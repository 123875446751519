import React from 'react';
import alloy from '@alloyidentity/web-sdk';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import { getKyc } from 'selectors/kyc';
import { getApplicationData } from 'selectors/getApplicationData';
import FormContainer from 'components/LoanForm/FormContainer';
import { getEnvironment, Environments } from 'utils/getEnvironment';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { KycStatus } from 'handlers/kyc';
import { AlloyKycResult } from 'enums/FlowNextResults';

import styles from './Alloy.module.scss';

enum Status {
  Completed = 'completed',
  WaitingReview = 'waiting_review',
  DataRequest = 'data_request',
  PendingDocuments = 'pending_documents',
  PendingAction = 'pending_action',
  Running = 'running',
}

const Alloy = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { journeyApplicationToken, journeyToken } = useSelector(getKyc);

  const { application } = useSelector(getApplicationData);
  if (!application) {
    throw new Error('Missing application data');
  }

  const openAlloy = () => {
    const alloyInitParams = {
      key: process.env.REACT_APP_ALLOY_SDK_KEY!,
      production: getEnvironment() === Environments.Production,
      color: { primary: '#795af7', secondary: '#454b54' },
      journeyApplicationToken,
      journeyToken,
      isNext: true,
    };
    alloy.init(alloyInitParams);
    alloy.open(callback);
  };

  // Callback
  const callback = (data: any) => {
    analytics.track(`Alloy ${data.status}`);
    if (data.status !== Status.PendingDocuments) {
      if (data.journey_application_status === KycStatus.Approved) {
        handleNext(AlloyKycResult.Continue);
      } else if (data.journey_application_status === KycStatus.Rejected) {
        handleNext(AlloyKycResult.Rejected);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer
        title="Upload Driver License"
        subtitle="Plannery is partnered with Alloy to verify your identity."
        className={styles.formContainer}
      >
        <Button className={styles.button} onClick={openAlloy}>
          Get Started
        </Button>
      </FormContainer>
    </>
  );
};

export default Alloy;
