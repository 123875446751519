import { combineReducers } from '@reduxjs/toolkit';

import preQualificationData from './preQualificationData';
import preQualificationResult from './preQualificationResult';
import yourName from './yourName';
import yourAddress from './yourAddress';
import creditReport from './creditReport';
import applicationStep from './applicationStep';
import preQualificationDecline from './preQualificationDecline';
import loanOffer from './loanOffer';
import docuSignEnvelope from './docuSignEnvelope';
import argyle from './argyle';
import applicationData from './applicationData';
import bankAccount from './bankAccount';
import filesUpload from './filesUpload';
import employmentData from './employmentData';
import userPayrollData from './userPayrollData';
import loanTermsData from './loanTerms';
import manualFlow from './manualFlow';
import employers from './employers';
import payrollProviders from './payrollProviders';
import userPayrollDataGet from './userPayrollDataGet';
import userHistory from './userHistory';
import referrals from './referrals';
import plaidPublicToken from './plaidPublicToken';
import emailService from './emailService';
import yourContact from './yourContact';
import kyc from './kyc';
import yourGoal from './yourGoal';
import paymentAccount from './paymentAccount';
import yourBirthDate from './yourBirthDate';
import yourEmployer from './yourEmployer';
import yourTotalIncome from './yourTotalIncome';
import methodAuth from './methodAuth';
import cardData from './cardData';
import theme from './theme';
import auth from './auth';

const rootReducer = combineReducers({
  loanTermsData,
  preQualificationData,
  preQualificationResult,
  preQualificationDecline,
  yourName,
  yourAddress,
  yourTotalIncome,
  creditReport,
  applicationStep,
  loanOffer,
  docuSignEnvelope,
  argyle,
  applicationData,
  bankAccount,
  filesUpload,
  employmentData,
  manualFlow,
  userPayrollData,
  userPayrollDataGet,
  employers,
  payrollProviders,
  userHistory,
  referrals,
  plaidPublicToken,
  emailService,
  yourContact,
  kyc,
  yourGoal,
  paymentAccount,
  yourBirthDate,
  yourEmployer,
  methodAuth,
  cardData,
  theme,
  auth,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
