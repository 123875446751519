import React from 'react';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';

import ReferralProgram from './ReferralProgram';

import styles from './ReferralProgram.module.scss';

const ReferralProgramScreen = ({ navigationInfo }: FlowComponentType) => (
  <>
    <FormNavigation {...navigationInfo} />
    <div className={styles.container}>
      <ReferralProgram />
    </div>
  </>
);

export default ReferralProgramScreen;
