import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserPayrollData } from 'thunks';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { GetUserDataResponse } from 'api/UserDataApi';
import { ConversionType, trackConversion } from 'utils/analytics';

export interface AboutYouResponse {
  [AboutYouVariable.FirstName]: string;
  [AboutYouVariable.LastName]: string;
}

interface AboutYouStateInterface {
  [AboutYouVariable.FirstName]: string;
  [AboutYouVariable.LastName]: string;
}

export const initialState: AboutYouStateInterface = {
  [AboutYouVariable.FirstName]: '',
  [AboutYouVariable.LastName]: '',
};

const aboutYou = createSlice({
  name: 'aboutYou',
  initialState,
  reducers: {
    setAboutYouData: (state: AboutYouStateInterface, { payload }: PayloadAction<AboutYouStateInterface>) => {
      const firstName = payload[AboutYouVariable.FirstName];
      const lastName = payload[AboutYouVariable.LastName];

      analytics.identify({ firstName, lastName, name: `${firstName} ${lastName}` });
      if (firstName && !state[AboutYouVariable.FirstName]) {
        trackConversion(ConversionType.CollectedName);
      }

      state[AboutYouVariable.FirstName] = firstName;
      state[AboutYouVariable.LastName] = lastName;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPayrollData.fulfilled, (state, { payload }: PayloadAction<GetUserDataResponse>) => {
      if (payload.isLinkSupported) {
        state[AboutYouVariable.FirstName] = !state[AboutYouVariable.FirstName]
          ? payload.aboutYou[AboutYouVariable.FirstName]
          : state[AboutYouVariable.FirstName];

        state[AboutYouVariable.LastName] = !state[AboutYouVariable.LastName]
          ? payload.aboutYou[AboutYouVariable.LastName]
          : state[AboutYouVariable.LastName];
      }
    });
  },
});

export const { setAboutYouData } = aboutYou.actions;

export default aboutYou.reducer;
