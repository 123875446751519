import React, { useState } from 'react';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import clsx from 'clsx';

import { RewardProgram } from './useReferralProgram';

import styles from './ReferralProgram.module.scss';

interface RewardItemProps {
  reward: RewardProgram;
  nextReward: RewardProgram | null;
  credits: number;
}

const RewardItem = ({ reward, nextReward, credits }: RewardItemProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const { id, title, description, showMoreContent, icon, creditsToRedeem } = reward;

  const redeemed = credits >= creditsToRedeem;

  return (
    <div
      className={clsx(styles.reward, {
        [styles.rewardActive]: nextReward?.id === id,
        [styles.rewardCompleted]: redeemed,
      })}
    >
      <div className={styles.iconContainer}>{redeemed ? <CheckIcon /> : icon}</div>
      <div className={styles.rewardData}>
        <p className={styles.rewardTitle}>{title}</p>
        <span className={styles.rewardDescription}>
          {description}{' '}
          {!showMore && showMoreContent && (
            <span className={styles.highlighted} onClick={() => setShowMore(!showMore)}>
              Show More {'>'}
            </span>
          )}
        </span>
        {showMore && <span className={clsx(styles.rewardDescription, styles.rewardShowMore)}>{showMoreContent}</span>}
      </div>
    </div>
  );
};

export default RewardItem;
