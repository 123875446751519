import { useEffect, useRef } from 'react';

const DELTA = 6;

const useRotateCard = (sensitivity = 2) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const prevAngle = useRef(0);
  const currentAngle = useRef(0);

  const onStart = (e: MouseEvent | TouchEvent) => {
    const body = document.querySelector('body');
    const card = cardRef.current!;
    const x = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
    const startX = e instanceof MouseEvent ? e.pageX : e.touches[0].pageX;
    const startY = e instanceof MouseEvent ? e.pageY : e.touches[0].pageY;

    const moveEvent = e instanceof MouseEvent ? 'mousemove' : 'touchmove';
    const endEvent = e instanceof MouseEvent ? 'mouseup' : 'touchend';

    window.addEventListener(moveEvent, rotate);
    function rotate(rotateEvent: MouseEvent | TouchEvent) {
      const rotateX = rotateEvent instanceof MouseEvent ? rotateEvent.clientX : rotateEvent.touches[0].clientX;
      const newAngle = (rotateX - x) / sensitivity + prevAngle.current;
      const normalizedAngle = (newAngle + 360) % 360;
      currentAngle.current = normalizedAngle;
      card.style.transform = `rotateY(${currentAngle.current}deg)`;

      // Change cursor style
      card.style.transition = '';
      card.style.cursor = 'grabbing';
      body!.style.cursor = 'grabbing';
      body!.classList.add('disableSelect');
    }

    prevAngle.current = currentAngle.current;

    window.addEventListener(endEvent, onMouseUp);
    function onMouseUp(event: MouseEvent | TouchEvent) {
      const currentX = event instanceof MouseEvent ? event.pageX : event.touches[0].pageX;
      const currentY = event instanceof MouseEvent ? event.pageY : event.touches[0].pageY;
      const diffX = Math.abs(currentX - startX);
      const diffY = Math.abs(currentY - startY);
      window.removeEventListener('mousemove', rotate);
      window.removeEventListener('touchmove', rotate);

      if (diffX < DELTA && diffY < DELTA) {
        card.style.transition = 'transform 300ms';
        // eslint-disable-next-line no-nested-ternary
        const newAngle = currentAngle.current < 90 ? 180 : currentAngle.current > 270 ? 180 : 0;
        currentAngle.current = newAngle;

        card.style.transform = `rotateY(${newAngle}deg)`;
      }

      // Change cursor style
      card.style.cursor = 'grab';
      body!.style.cursor = 'default';
      body!.classList.remove('disableSelect');
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchend', onMouseUp);
    }
  };

  useEffect(() => {
    if (cardRef.current) {
      const card = cardRef.current;
      card.addEventListener('mousedown', onStart);
      card.addEventListener('touchstart', onStart);
    }
  }, [cardRef]);

  return { cardRef };
};

export default useRotateCard;
