import React from 'react';
import { DisplayedReward } from 'components/CardFlow/ReferralProgram/referralRewards';
import clsx from 'clsx';
import { ReactComponent as GiftBoxIcon } from 'images/gift-box.svg';

import styles from './GiftCount.module.scss';

interface GiftCountProps {
  rewards: DisplayedReward[];
  credits: number;
}

const GiftCount = ({ rewards, credits }: GiftCountProps) => (
  <div className={styles.redeemedRewardsContainer}>
    <div className={styles.giftBoxesContainer}>
      {[...Array(credits > 4 ? credits : 4)].map((_, index) => (
        <GiftBoxIcon
          key={index}
          className={clsx(styles.giftBoxIcon, {
            [styles.purpleBox]: credits >= index + 1,
          })}
        />
      ))}
    </div>

    <div className={styles.giftCountContainer}>
      <p className={styles.giftCountText}>Gifts</p>
      <p className={styles.giftCountTextLight}>
        <span className={styles.strong}>{credits}</span>
        {credits > rewards.length ? '' : `/${rewards.length}`} received
      </p>
    </div>
  </div>
);

export default GiftCount;
