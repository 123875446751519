import React from 'react';
import clsx from 'clsx';
import { ReactComponent as PlusIcon } from 'images/plus-icon-thick.svg';
import { ReactComponent as PlusIconWhite } from 'images/plus-icon-thick-white.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'handlers';
import { ReactComponent as LoveIcon } from 'images/love-icon.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { ReactComponent as PlanneryLogo } from 'images/plannery-logo-2.svg';
import { Themes } from 'styles/themes/ThemeTypes';

import styles from './Header.module.scss';

const Header = () => {
  const { partner_branding: partnerBranding } = useSelector((state: RootState) => state.preQualificationData);
  const { theme, themeStyles } = useLayoutTheme();

  const isWhiteLogo = theme === Themes.BLUE || theme === Themes.PURPLE || theme === Themes.DARK;

  return (
    <div className={clsx(styles.header, themeStyles.header)}>
      <div className={styles.topRow}>
        <PlanneryLogo className={clsx(styles.logo, themeStyles.logo)} />
        {partnerBranding && (
          <>
            {isWhiteLogo ? <PlusIconWhite className={styles.plus} /> : <PlusIcon className={styles.plus} />}
            <img
              className={styles.logo}
              src={`https://assets.planneryapp.com/media/partners/${partnerBranding}/logo${
                isWhiteLogo ? '-white' : ''
              }.png`}
              alt={partnerBranding}
            />
          </>
        )}
      </div>
      {!partnerBranding && (
        <span className={clsx(styles.lovePhrase, themeStyles.lovePhrase)}>
          We <LoveIcon className={styles.loveIcon} /> Healthcare Workers
        </span>
      )}
    </div>
  );
};

export default Header;
