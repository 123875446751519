import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { ReferralData, ReferralProgramReward } from 'handlers/cardData';

import { AvailableRewards, DisplayedReward } from './referralRewards';

import styles from './ReferralProgram.module.scss';

export type RewardProgram = ReferralProgramReward & DisplayedReward;

interface UseReferralProgramProps {
  credits: number;
  rewards: RewardProgram[];
  nextReward: RewardProgram | null;
  referredApplicants: ReferralData[];
  thanksLabel: string;
}

const numberToWord: Record<number, string> = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
};

const getReferredApplicantsLabel = (referredApplicants: ReferralData[]): string => {
  const referralList = referredApplicants.map((applicant) => applicant.firstName);
  const lastReferral = referralList.slice(-1);

  if (!referralList.length) {
    return '';
  }

  const thanksLabel = 'Thanks for referring ';

  if (referralList.length === 1) {
    return `${thanksLabel} ${referralList[0]}! `;
  }
  const referralNames = referralList.slice(0, -1).join(', ') + (lastReferral ? ` and ${lastReferral}` : '');
  return `${thanksLabel} ${referralNames}! `;
};

const getReferralsLeftLabel = (mostValuableReward: RewardProgram | undefined, credits: number): string => {
  if (!mostValuableReward) {
    return '';
  }
  const referralsLeft = mostValuableReward.creditsToRedeem - credits;
  if (referralsLeft <= 0) {
    return mostValuableReward.rewardClaimedLabel!;
  }

  return `${numberToWord[referralsLeft]} more referral${referralsLeft > 1 ? 's' : ''} ${
    mostValuableReward.referralsLeftLabel
  }`;
};

const useReferralProgram = (): UseReferralProgramProps => {
  const { waitListPosition, referralProgram } = useSelector(getCardData);

  const { rewards, referredApplicants, credits } = referralProgram!;

  const referralCount = referredApplicants?.length || 0;

  const getNextReward = useCallback((): RewardProgram | null => {
    const parsedRewards = parseReferralRewards();

    const nextRewardIndex = credits <= parsedRewards.length ? credits : null;

    if (nextRewardIndex === null) {
      return null;
    }

    return parsedRewards[nextRewardIndex];
  }, [referralProgram?.rewards]);

  const parseReferralRewards = useCallback(
    (): RewardProgram[] =>
      rewards.map((reward: ReferralProgramReward) => {
        const rewardDisplayData = AvailableRewards[reward.id]({ waitListPosition, styles });

        const redeemed = reward.timesRedeemed > 0;

        return {
          ...reward,
          redeemed,
          ...rewardDisplayData,
        };
      }),
    [rewards, referralCount, waitListPosition],
  );

  const mostValuableReward = rewards.reduce(
    (mostValuable, current) => (current.weightPoints > mostValuable.weightPoints ? current : mostValuable),
    rewards[rewards.length - 1],
  );

  const referralsInvitedLabel = () => {
    const mostValuable = mostValuableReward && {
      ...AvailableRewards[mostValuableReward.id]({ waitListPosition, styles }),
      ...mostValuableReward,
    };

    if (!credits) {
      return mostValuable?.rewardToClaimLabel ?? '';
    }

    let referralSummaryLabel = '';

    const referredLabel = getReferredApplicantsLabel(referredApplicants);
    referralSummaryLabel = referralSummaryLabel.concat(referredLabel);

    const referralsLeftLabel = getReferralsLeftLabel(mostValuable, credits);
    referralSummaryLabel = referralSummaryLabel.concat(referralsLeftLabel);

    return referralSummaryLabel;
  };

  return {
    credits,
    rewards: parseReferralRewards(),
    nextReward: getNextReward(),
    referredApplicants,
    thanksLabel: referralsInvitedLabel(),
  };
};

export default useReferralProgram;
