import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePath } from 'enums/Routes';
import { CurrentFlow } from 'enums/CurrentFlow';
import ThemeContext, { themeList } from 'pages/Root/ThemeContext';
import defaultTheme from 'components/MainLayout/MainLayout.module.scss';
import { Themes } from 'styles/themes/ThemeTypes';

import useCurrentFlow from './useCurrentFlow';
import { useQueryParams } from './useQueryParam';

export interface ThemeConfig {
  name: Themes;
  routes: RoutePath[];
  flows: CurrentFlow[];
  styles: Record<string, string>;
}

const useLayoutTheme = () => {
  const { pathname } = useLocation();
  const { currentFlow } = useCurrentFlow();
  const { theme, setTheme } = useContext(ThemeContext);

  const params = useQueryParams();
  const version = params.get('cardVersion');

  const themeConfig = themeList[theme];

  if (!themeConfig) {
    return { theme: Themes.DEFAULT, themeStyles: defaultTheme, setTheme, shouldApplyTheme: false, version };
  }

  const { routes, flows, styles } = themeConfig;

  const shouldApplyTheme = routes.includes(pathname as RoutePath) && flows.includes(currentFlow ?? CurrentFlow.V1);

  if (shouldApplyTheme) {
    return { theme, themeStyles: styles, setTheme, shouldApplyTheme, version };
  }

  return { theme: Themes.DEFAULT, themeStyles: defaultTheme, setTheme, shouldApplyTheme, version };
};

export default useLayoutTheme;
