import React from 'react';
import { ReferralReward } from 'handlers/cardData';
import { ReactComponent as WaitListIcon } from 'images/waitlist.svg';
import { ReactComponent as CameraIcon } from 'images/camera.svg';
import { ReactComponent as ShoesIcon } from 'images/shoes.svg';
import { ReactComponent as GalesIcon } from 'images/gales.svg';
import { ReactComponent as CashBackIcon } from 'images/cashback-icon.svg';
import GalesShoes from 'images/gales-shoes.png';
import { formatNumber } from 'utils/formatNumber';
import clsx from 'clsx';

export interface DisplayedReward {
  id: ReferralReward;
  title: string | JSX.Element;
  description: string | JSX.Element;
  showMoreContent?: string | JSX.Element;
  icon: JSX.Element;
  shareLabel: string;
  thanksLabel: string;
  rewardClaimedLabel?: string;
  rewardToClaimLabel?: string;
  referralsLeftLabel?: string;
}

interface RewardProps {
  styles: Record<string, string>;
  waitListPosition?: number;
}

const MoveUpWaitlist = ({ waitListPosition }: RewardProps): DisplayedReward => ({
  id: ReferralReward.MoveUpWaitlist,
  title: 'Move up the waitlist',
  description: `There are ${
    waitListPosition ? formatNumber(waitListPosition - 1) : ''
  } people in front of you. You'll jump ahead with each referral.`,
  icon: <WaitListIcon />,
  shareLabel: 'Share the link to move up our waitlist.',
  thanksLabel: 'to move up the waitlist',
});

const CameoFeaturing = ({ styles }: RewardProps): DisplayedReward => ({
  id: ReferralReward.CameoFeaturing,
  title: 'Be featured on Cameo',
  description: "Each week we'll select one nurse to be featured on Cameo.",
  showMoreContent: (
    <>
      On Cameo, you'll be featured as a healthcare hero and have the opportunity to be paid to create videos for people
      you inspire.{' '}
      <div className={clsx(styles.highlighted, styles.visitLink)}>
        <a target="_blank" rel="noreferrer" href="https://www.cameo.com">
          Visit Cameo {'>'}
        </a>
      </div>
    </>
  ),
  icon: <CameraIcon />,
  shareLabel: 'Share the link to be featured on Cameo.',
  thanksLabel: 'be featured on Cameo',
});

const FreeGales = ({ styles }: RewardProps): DisplayedReward => ({
  id: ReferralReward.FreeGales,
  title: (
    <span className={styles.galesTitle}>
      Free pair of <GalesIcon className={styles.galesIcon} />
    </span>
  ),
  description: (
    <>
      Shoes designed for nurses, with comfort and protection, <span className={styles.bold}>worth up to $120</span>.
    </>
  ),
  showMoreContent: (
    <>
      Gales® is the first footwear designed to disperse energy and reduce impact on knees, backs, and joints from long
      12+ hour shifts, Gales was founded to support essential workers who deserve better care.
      <img className={styles.galesShoes} src={GalesShoes} alt="Gales shoes" />
      <div className={clsx(styles.highlighted, styles.visitLink)}>
        <a target="_blank" rel="noreferrer" href="https://www.weargales.com">
          Visit Gales {'>'}
        </a>
      </div>
    </>
  ),
  icon: <ShoesIcon />,
  shareLabel: 'Share the link for your free pair of Gales shoes.',
  thanksLabel: 'get your free pair of Gales shoes',
  rewardClaimedLabel: "You've earned your free pair of Gales shoes.",
  rewardToClaimLabel: 'Refer 3 friends to earn your Gales shoes',
  referralsLeftLabel: 'earns you free Gales shoes.',
});

const CashBack25 = ({ styles }: RewardProps): DisplayedReward => ({
  id: ReferralReward.CashBack25,
  title: 'More cash back',
  description: (
    <>
      Get extra cash back, up to <span className={styles.strong}>$25 per referral</span>.
    </>
  ),
  showMoreContent: (
    <ul>
      <li>
        Starting from your 4th gift and continuing through your 10th gift, you'll get an additional $25 cash back for
        each one, up to a total of $175.
      </li>
      <li>During this time, your regular 2% cash back rate will go up to 3% until you reach the $175 limit.</li>
      <li>This special offer lasts for your first year as a cardholder.</li>
    </ul>
  ),
  icon: <CashBackIcon />,
  shareLabel: 'Share the link to get up to $25 for each referral.',
  thanksLabel: 'get up to $25 per referral',
});

export const AvailableRewards = {
  MoveUpWaitlist,
  CameoFeaturing,
  FreeGales,
  CashBack25,
};
