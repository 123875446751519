import React, { FC } from 'react';
import clsx from 'clsx';

import CommonQuestions from 'components/CommonQuestions';
import Header from 'components/Header';
import Footer from 'components/Footer';

import ConfettiMobil from 'images/сonfetti-mobil.svg';
import ConfettiDesktop from 'images/сonfetti-desktop.svg';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { ReactComponent as CardBackground } from 'images/card-background.svg';
import { ReactComponent as Hexagons } from 'images/background-hexagons.svg';
import { ReactComponent as HexagonsMiddle } from 'images/background-hexagons-middle.svg';

import styles from './MainLayout.module.scss';

interface MainLayoutProps {
  confettiBackground?: boolean;
}

const MainLayout: FC<MainLayoutProps> = ({ children, confettiBackground }) => {
  const { themeStyles, shouldApplyTheme, version } = useLayoutTheme();

  return (
    <div className={clsx(styles.layoutContainer, themeStyles.layoutContainer)}>
      <Header />
      <div className={clsx(styles.layoutContent, themeStyles.layoutContent)}>
        {confettiBackground && (
          <picture>
            <source media="(max-width: 1023px)" srcSet={ConfettiMobil} />
            <source media="(min-width: 1024px)" srcSet={ConfettiDesktop} />
            <img src={ConfettiDesktop} alt="Background" className={styles.confetti} />
          </picture>
        )}
        {children}
        <CommonQuestions />
      </div>
      <Footer />
      {shouldApplyTheme && version === '1' && (
        <>
          <>
            <Hexagons className={clsx(styles.hexagonTop, themeStyles.hexagons)} />
            <HexagonsMiddle className={clsx(styles.hexagonMiddle, themeStyles.hexagons)} />
            <Hexagons className={clsx(styles.hexagonBottom, themeStyles.hexagons)} />
          </>
        </>
      )}
      {shouldApplyTheme && version === '2' && <CardBackground className={styles.cardBackground} />}
    </div>
  );
};

export default MainLayout;
