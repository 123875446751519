import React, { useState } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import { EMAIL_PATTERN } from 'components/LoanForm/YourContact/YourContact';
import ShareModal from 'components/ShareModal';
import { SHARE_MESSAGE } from 'components/ShareModal/ShareModal';
import { useSelector } from 'react-redux';
import { getCardData } from 'selectors/getCardData';
import { ReactComponent as GiftsIcon } from 'images/gifts.svg';
import Loader from 'components/Loader';
import GiftCount from 'components/Card/GiftsCount/GiftCount';

import useReferralProgram from './useReferralProgram';
import RewardItem from './RewardItem';

import styles from './ReferralProgram.module.scss';

const ReferralProgram = () => {
  const useShareModal = true;
  const [inviteEmail, setInviteEmail] = useState('');
  const [showShareModal, setShowShareModal] = useState(false);

  const { referralLink, isLoading } = useSelector(getCardData);
  const { rewards, nextReward, credits } = useReferralProgram();

  const isEmailValid = inviteEmail.match(EMAIL_PATTERN);

  const handleClick = () => {
    analytics.track('Card CTA Share Button Clicked');
    if (useShareModal) {
      setShowShareModal(true);
    } else {
      handleInvite();
    }
  };

  const handleInvite = () => {
    // TODO: Send email invite. Currently using share modal
  };

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#795af7" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.header}>
        <p className={styles.title}>Referral Program</p>
        <p className={styles.subtitle}>
          Earn a gift for each referral. Plus, you'll give a gift to each friend your refer.
        </p>

        {credits > 0 ? <GiftCount credits={credits} rewards={rewards} /> : <GiftsIcon className={styles.giftsIcon} />}
      </div>

      <div className={styles.rewardsStepper}>
        {rewards.map((reward) => (
          <RewardItem key={reward.id} reward={reward} nextReward={nextReward} credits={credits} />
        ))}
      </div>

      {!useShareModal && (
        <div className={styles.inviteContainer}>
          <p className={styles.inviteTitle}>Invite a friend or colleague:</p>
          <Input
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            className={styles.input}
            placeholder="Email Address"
          />
        </div>
      )}

      <Button disabled={!isEmailValid && !useShareModal} onClick={handleClick} className={styles.button}>
        {useShareModal ? 'Invite friend or colleague' : 'Send Invite'}
      </Button>

      {showShareModal && (
        <ShareModal
          modalDescription={nextReward?.shareLabel}
          shareMessage={SHARE_MESSAGE}
          handleClose={() => setShowShareModal(false)}
          referralLink={referralLink || 'https://planneryapp.com'}
        />
      )}
    </>
  );
};

export default ReferralProgram;
