import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VerificationResult } from 'api/LoanOfferApi';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { verifyApplication } from 'thunks';

export enum KycStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  ManualReview = 'Manual Review',
  Pending = 'Pending',
  PendingDocuments = 'Pending Documents',
}

export interface KycResult {
  status: ApplicationStatusName;
}

export interface AlloyKycResult extends KycResult {
  journeyApplicationToken: string;
  journeyToken: string;
}

interface KycState {
  isLoading: boolean;
  status?: KycStatus;
  journeyApplicationToken?: string;
  journeyToken?: string;
  kycSkipped: boolean;
}

const initialState: KycState = {
  isLoading: false,
  kycSkipped: false,
};

const kyc = createSlice({
  name: 'kyc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyApplication.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyApplication.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyApplication.fulfilled, (state, { payload }: PayloadAction<VerificationResult>) => {
      state.isLoading = false;
      if (payload.kycResult) {
        const { journeyApplicationToken, journeyToken } = payload.kycResult;
        state.journeyApplicationToken = journeyApplicationToken;
        state.journeyToken = journeyToken;
      } else {
        state.kycSkipped = true;
      }
    });
  },
});

export default kyc.reducer;
